/* AL Style Variables */
@import '../colors';

$primary:       #000066;
$secondary:     #5D9813;

$font-family-base: 'Open Sans', sans-serif;

/* Primary Page-Styling Variables */
$body-bg:                           #495058;
$body-color:                        #222;

$btn-pop-bg:                        $secondary;

$dropdown-bg:                       $primary;
$dropdown-hover-bg:                 #393988;
$dropdown-divider-bg:               #777;

$dropdown-link-color:               #fff;
$dropdown-link-hover-color:         darken(#fff, 10%);
$dropdown-link-hover-bg:            #393988;

$mobile-navbar-bg:                  #495058;
$static-page-header-color:           $primary;
$static-page-subtitle-color:        #333;

/* Proccess Page Styling */
$process-page-themes: (
  "claim": $primary,
  "holder": $primary
);



/* Image Size & Image Source Variables */
$banner-image-url:                    url('/images/banner_img_temp.png');
$body-background-image-url:           url('/images/bg_texture.png');

$claim-header-logo-url:               url('/images/main_logo.png');
$claim-header-logo-height:            92px;
$claim-header-logo-width:             550px;
$claim-header-logo-resize-value-map:  (width: 180px,
                                       height: 52px,
                                       background-image: url('/images/process_logo.png'));

$holder-header-logo-url:              $claim-header-logo-url;
$holder-header-logo-height:           $claim-header-logo-height;
$holder-header-logo-width:            $claim-header-logo-width;
$holder-header-logo-resize-value-map: $claim-header-logo-resize-value-map;

$home-logo-url:                       url('/images/main_logo.png');
$home-logo-width:                     550px;
$home-logo-height:                    92px;
$home-logo-resize-value-map:          (width: 250px,
                                       height: 73px,
                                       background-image: url('/images/state_seal.png'));

$treasurer-logo-url:                  $home-logo-url;
$treasurer-logo-width:                $home-logo-width;
$treasurer-logo-height:               $home-logo-height;
$treasurer-logo-resize-value-map:     $home-logo-resize-value-map;

$footer-logo-url:                     url('/images/state_seal.png');
$footer-logo-width:                   150px;
$footer-logo-height:                  150px;

$claimform-image-url:                 url('/images/sample_claim_form.jpg');


$icon-email-url:                      none;
$icon-facebook-url:                   url('/images/facebook.png');
$icon-instagram-url:                  none;
$icon-twitter-url:                    none;
$icon-youtube-url:                    none;
$icon-linkedin-url:                   none;

$social-icon-width:                   50px;
$social-icon-height:                  50px;

$tooltip-icon-color:                  $primary;
$jumbotron-bg:                        $fade-dark;

.process-title {
  visibility: hidden;
}

.nc-claim-search-img {
  height: 223px;
  @media (max-width: 575.98px) {
      height: 108px;
   }
}

